import { readItems } from '@directus/sdk';
import { Menu, Web, db } from 'data';
import _ from 'lodash';

export type NichosStaticProps = {
	title?: string;
	web?: Web;
	menus?: Menu[];
};

export type NichosGetStaticProps = {
	props: NichosStaticProps;
};

export const getStaticPropsNicho = (
	staticProps?: (params?: any) => Promise<NichosGetStaticProps> | NichosGetStaticProps
) => {
	return async ({ params }: { params: any }) => {
		const cms = db();
		let json: NichosGetStaticProps = { props: {} };

		const webResult = await cms.request(
			readItems('webs', { filter: { code: { _eq: process.env.WEB_CODE } } })
		);

		json.props.web = webResult[0] as any;

		const menuResult = await cms.request(
			readItems('menus', { filter: { web: { code: { _eq: process.env.WEB_CODE } } }, sort: 'sort' })
		);

		json.props.menus = menuResult as any;

		if (staticProps && typeof staticProps === 'function') {
			const customJson = await staticProps(params);

			json = _.merge(json, customJson);
		}

		return json;
	};
};
