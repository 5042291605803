import Image from 'next/image';
import _ from 'lodash';
import { marked } from 'marked';
import fs from 'fs';
import { FunctionComponent } from 'react';
import { BaseSchema, News, NewsCategory, Product, Web, cdn, db } from 'data';
import ProductCard from '../../components/cards/product-card';
import { readItems } from '@directus/sdk';

export type ANewPageComponentProps = {
	aNew: News;
	content: string;
	web: Web;
	products: Product[];
};

type StaticProps = {
	//category: NewsCategory;
	aNew: News;
};

export const ANewPageComponent: FunctionComponent<ANewPageComponentProps> = ({
	aNew,
	content,
	web,
	products
}: ANewPageComponentProps) => {
	const tags = aNew.tags.map((tag: string) => {
		return (
			<a
				key={tag}
				href="#"
				className="m-1 px-4 py-1 bg-black text-gray-200 inline-flex items-center justify-center mb-2">
				{tag}
			</a>
		);
	});

	const productsContent = _.map(products, (product) => {
		return <ProductCard product={product} />;
	});

	const productsElements = <div className="grid grid-cols-2">{productsContent}</div>;

	return (
		<>
			<main className="mt-10">
				<div className="mb-4 md:mb-0 w-full max-w-screen-md mx-aut">
					<div className=" left-0 bottom-0 w-full h-full z-10"></div>
					<Image
						src={cdn(aNew.image)}
						className="lg:h-48 md:h-36 w-full object-cover object-center scale-110 transition-all duration-400 hover:scale-100"
						alt={`${web.name} - ${aNew.name}`}
						title={`${web.name} - ${aNew.name}`}
						width={800}
						height={450}
					/>

					<div className="p-4 bottom-0">
						{tags}
						<h1 className="text-4xl font-semibold dark:text-gray-300 text-gray-700 leading-tight">
							{aNew.name}
						</h1>
						{/* <div className="flex mt-3">
						<img
							src="https://randomuser.me/api/portraits/men/97.jpg"
							className="h-10 w-10 rounded-full mr-2 object-cover"
						/>
						<div>
							<p className="font-semibold text-gray-200 text-sm"> Mike Sullivan </p>
							<p className="font-semibold text-gray-400 text-xs"> 14 Aug </p>
						</div>
					</div> */}
					</div>
				</div>
				{productsElements}

				<div
					className="px-4 lg:px-0 mt-12 dark:text-gray-300 text-gray-700 max-w-screen-md mx-auto text-lg leading-relaxed"
					dangerouslySetInnerHTML={{ __html: content }}></div>
			</main>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: JSON.stringify(
						{
							'@context': 'https://schema.org',
							'@type': 'NewsArticle',
							headline: aNew.name,
							image: [cdn(aNew.image)],
							datePublished: aNew.date_created,
							dateModified: aNew.date_updated,
							author: [
								{
									'@type': 'Person',
									name: 'Juan Benavides',
									jobTitle: 'Chief Technology Officer - CTO',
									url: 'https://www.juanbenavides.es'
								}
							]
						},
						null,
						'\t'
					)
				}}></script>
		</>
	);
};

export async function ANewPageStaticProps({ aNew }: StaticProps) {
	const cms = db<BaseSchema>();

	let idFilter = undefined;
	let products: Product[] = [];

	if (aNew.products && aNew.products.length > 0) {
		idFilter = { _in: _.map(aNew.products, (product) => product.toString()) };

		products = await cms.request<Product[]>(
			readItems('products', {
				filter: {
					status: { _eq: 'published' },
					web: {
						code: { _eq: process.env.WEB_CODE }
					},
					id: idFilter
				}
			})
		);
	}

	return {
		props: {
			products,
			content: marked.parse(aNew.content, {}, (err, result) => {
				result = result.replace(
					/<h2 id=(.*)>/gm,
					'<h2 class="text-3xl font-semibold dark:text-gray-300 text-gray-700 mt-5 mb-5" id=$1>'
				);

				result = result.replace(
					/<h3 id=(.*)>/gm,
					'<h3 class="text-2xl font-semibold dark:text-gray-300 text-gray-700 mt-5 mb-5" id=$1>'
				);

				result = result.replace(/<p>/gm, '<p class="mt-5">');
				result = result.replace(/<ul>/gm, '<ul class="list-disc ml-7">');
				result = result.replace(/<ol>/gm, '<ol class="list-decimal ml-7">');
				result = result.replace(/<a/gm, '<a class="underline"');

				//result = result.replace(/[[CASCO]]/gm, <ProductCard />);

				return result;
			}),
			aNew,
			title: `Noticias - ${aNew?.name}`
		}
	};
}
