import Header from './header';
import { useEffect } from 'react';
import { SessionProvider } from 'next-auth/react';
import { Footer, Navbar, NichosStaticProps } from 'ui';
import Link from 'next/link';
import Image from 'next/image';
import { Analytics } from '@vercel/analytics/react';
import { NextComponentType, NextPageContext } from 'next';
import { cdn } from 'data';
import Script from 'next/script';

type PageType = {
	Component: NextComponentType<NextPageContext, any, any>;
	pageProps: NichosStaticProps;
};

function NichosApp({ Component, pageProps }: PageType & NichosStaticProps) {
	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
		}
	}, []);

	const MyComponent: any = Component;

	const content = (
		<>
			<Header title={pageProps.title} web={pageProps.web!} />
			<main className="dark:bg-gray-800 bg-white h-screen">
				<header className="flex items-center z-30 w-full">
					<Navbar menu={pageProps.menus!} web={pageProps.web!} title="AyudaFinanzas" />
				</header>
				<div className="bg-white dark:bg-gray-800 flex z-20 items-center overflow-hidden">
					<div className="w-full mx-auto px-6 flex py-5 justify-center">
						<MyComponent {...pageProps} />
					</div>
				</div>
				<Footer pages={pageProps.menus!} web={pageProps.web!} />
			</main>
			<Script
				src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7520941738674114"
				async
			/>
			<Analytics />
		</>
	);

	if (pageProps.web?.hasLogin) {
		return (
			<SessionProvider basePath="/api/auth" baseUrl="/">
				{content}
			</SessionProvider>
		);
	} else {
		return content;
	}
}

export default NichosApp;
