import { Web } from 'data';
import Head from 'next/head';

type HeaderProps = {
	title?: string;
	web: Web;
};

const Header = (props: HeaderProps) => {
	const title = props.title ? `${props.title} - ${props.web.title}` : props.web.title;

	return (
		<Head>
			<title>{title}</title>
			<meta name="title" content={title} />
			<link rel="icon" href="/favicon.ico" />
			<link rel="manifest" href="/manifest.webmanifest"></link>
			<meta name="description" content={props.web.description} />
			<meta name="keywords" content={props.web.keywords.join(', ')} />
			<meta name="robots" content="index, follow" />
			<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
			<meta name="language" content="Spanish" />
			<meta name="revisit-after" content="1 days" />

			<meta name="application-name" content={props.web.code} />
			<meta name="apple-mobile-web-app-capable" content="yes" />
			<meta name="apple-mobile-web-app-status-bar-style" content="default" />
			<meta name="apple-mobile-web-app-title" content={props.web.code} />
			<meta name="format-detection" content="telephone=no" />
			<meta name="mobile-web-app-capable" content="yes" />
			<meta name="msapplication-config" content="/icons/browserconfig.xml" />
			<meta name="msapplication-TileColor" content="#2B5797" />
			<meta name="msapplication-tap-highlight" content="no" />
			<meta name="theme-color" content="#000000" />

			<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
			<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
			<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />

			{/* 	<link
    rel="preload"
    href={imageUrl}
    as="image"
  /> */}
		</Head>
	);
};

export default Header;
