import '../styles/globals.css';
import type { AppProps } from 'next/app';
import NichosApp from 'ui/src/components/layout/app';

type PageType = {
	title?: string;
};

function MyApp({ Component, pageProps }: AppProps<PageType>) {
	return <NichosApp pageProps={pageProps!} Component={Component} />;
}

export default MyApp;
