import { FunctionComponent } from 'react';

type PageProps = {
	domain: string;
};

export const LegalDisclaimerComponent: FunctionComponent<PageProps> = ({ domain }: PageProps) => {
	return (
		<>
			<div className="flex flex-col text-black dark:text-white">
				<h2 className="text-4xl font-extrabold">Aviso legal</h2>
				<h3 className="text-2xl font-extrabold">
					AVISO LEGAL - CONDICIONES DE USO DE LA PLATAFORMA
				</h3>
				<p>
					El presente Aviso Legal regula el uso de la página web {domain} (en adelante, “la
					Plataforma”) titularidad de Juan Benavides Romero, con NIF 54098996Y, y dirección en Calle
					Chichón 24, 28723, Pedrezuela, Madrid, contacto: juan@altairstudios.es (en adelante , “el
					Propietario”).
				</p>
				<p>
					La utilización de la Plataforma requiere la aceptación del usuario de todas y cada una de
					las cláusulas que se indican en el presente Aviso Legal, así como la aceptación de cada
					una de las advertencias o cláusulas específicas que se establezcan para la contratación de
					determinados servicios, productos o uso de zonas de la Plataforma.
				</p>
				<p>
					En caso de no aceptar las cláusulas establecidas en el presente Aviso Legal, el usuario
					deberá abstenerse de acceder y/o utilizar los servicios y/o contenidos puestos a su
					disposición en la Plataforma.
				</p>

				<h3 className="text-2xl font-extrabold">USO DE LA PLATAFORMA</h3>
				<p>
					El usuario se obliga a no utilizar la Plataforma para la realización de actividades
					contrarias a las leyes, a la moral, al orden público y, en general, a hacer un uso
					conforme a las condiciones establecidas en el presente Aviso Legal. Asimismo, se obliga a
					no realizar actividades publicitarias o de explotación comercial remitiendo mensajes que
					utilicen una identidad falsa.
				</p>
				<p>
					El Propietario actúa exclusivamente como responsable de la Plataforma en su condición de
					prestador de un servicio de información sobre sus propios servicios, no haciéndose
					responsable de los contenidos que, en contravención al presente Aviso, los usuarios
					pudieran enviar o publicar, siendo el usuario el único responsable de la veracidad y
					licitud de los mismos.
				</p>
				<p>
					No está permitido y, por tanto, sus consecuencias serán de la exclusiva responsabilidad
					del Usuario, el acceso o la utilización de la Plataforma con fines ilegales o no
					autorizados, con o sin finalidad económica. En particular, y sin que el siguiente listado
					tenga carácter absoluto ni cerrado, queda prohibido:
				</p>
				<ul>
					<li>
						Usar la Plataforma de forma que pueda provocar daños, interrupciones, ineficiencias o
						defectos en su funcionamiento o en el ordenador de un tercero;
					</li>
					<li>
						Usar la Plataforma para la transmisión, instalación o publicación de cualquier virus,
						código malicioso u otros programas o archivos perjudiciales;
					</li>
					<li>Usar la Plataforma para recoger datos de carácter personal de otros usuarios;</li>
					<li>
						Registrarse a través de la Plataforma con una identidad falsa, suplantando a terceros o
						utilizando un perfil o realizando cualquier otra acción que pueda confundir a otros
						Usuarios sobre la identidad del origen de un mensaje;
					</li>
					<li>
						Acceder sin autorización a cualquier sección de la Plataforma, a otros sistemas o redes
						conectados a la Plataforma, a ningún servidor de el Propietario, ni a los servicios
						ofrecidos a través de la Plataforma, por medio de pirateo o falsificación, extracción de
						contraseñas o cualquier otro medio ilegítimo;
					</li>
					<li>
						Quebrantar, o intentar quebrantar, las medidas de seguridad o autenticación de la
						Plataforma o de cualquier red conectada al mismo, o las medidas de seguridad o
						protección inherentes a los contenidos ofrecidos en la Plataforma;
					</li>
					<li>
						Llevar a cabo alguna acción que provoque una saturación desproporcionada o innecesaria
						en la infraestructura de la Plataforma o en los sistemas o redes de El Propietario, así
						como en los sistemas y redes conectados a la Plataforma; o
					</li>
					<li>
						Subir documentación a la Plataforma que pueda contener virus, código malicioso o
						archivos perjudiciales.
					</li>
				</ul>
				<p>
					El Propietario podrá interrumpir el servicio de la Plataforma que esté siendo utilizado
					por el usuario y resolver de modo inmediato la relación con el usuario si detecta un uso
					de la Plataforma o de cualquiera de los servicios que en el mismo se ofertan que pueda
					considerarse contrario a lo expresado en el presente Aviso Legal.
				</p>
				<p>
					El incumplimiento de cualquiera de las anteriores obligaciones por el Usuario podrá llevar
					aparejada la adopción por el Propietario, de las medidas oportunas amparadas en Derecho y
					en el ejercicio de sus derechos u obligaciones, pudiendo llegar a la eliminación o bloqueo
					de la cuenta del Usuario infractor, sin que medie posibilidad de indemnización alguna por
					los daños y perjuicios causados.
				</p>
				<p>
					El Usuario que incumpla estas prohibiciones será responsable de cualquier reclamación que
					se produzca como consecuencia de ello. Aunque no se produjera ninguna reclamación de un
					tercero, el Propietario se reserva la posibilidad de impedir el acceso a la Plataforma o
					de la posibilidad de participar en los espacios habilitados en el mismo a los usuarios que
					incumplan estas condiciones.
				</p>
				<h3 className="text-2xl font-extrabold">ENLACES O LINKS</h3>
				<p>
					La Plataforma puede incluir enlaces o links a sitios de terceros. Las páginas web o
					plataformas pertenecientes a terceros no han sido revisadas ni son objeto de controles por
					parte de el Propietario que no podrá ser considerada responsable de los contenidos de
					estos sitios web ni de las medidas que se adopten relativas a su privacidad o al
					tratamiento de sus datos de carácter personal.
				</p>
				<p>
					El Propietario recomienda la lectura detenida de las condiciones de uso y la política de
					privacidad de estos sitios.
				</p>
				<p>
					En caso de estar interesado en activar un enlace a alguna de las páginas web de el
					Propietario el Usuario deberá comunicarlo, obteniendo el consentimiento expreso para crear
					el enlace. el Propietario se reserva el derecho de oposición a la activación de enlaces
					con su sitio web.
				</p>
				<h3 className="text-2xl font-extrabold">MENORES DE EDAD</h3>
				<p>
					El propietario recuerda a los usuarios mayores de edad, que tengan a su cargo menores, que
					será de su exclusiva responsabilidad determinar qué servicios y/o contenidos son no
					apropiados para la edad de estos últimos.
				</p>
				<p>
					El propietario les informa que existen programas informáticos que permiten filtrar y
					bloquear el acceso a determinados contenidos y servicios, de tal forma que se pueda
					decidir cuáles son los contenidos y servicios de Internet a los que los menores pueden
					tener acceso y a cuáles no.
				</p>
				<h3 className="text-2xl font-extrabold">REGISTRO DE USUARIOS</h3>
				<p>
					El acceso a parte de los contenidos de la Plataforma es completamente gratuito, sin
					perjuicio de que pueden existir apartados o servicios particulares que requieran para su
					uso y disfrute el abono de alguna cantidad económica, de lo cual en todo caso se informará
					debidamente al Usuario, y que éste deberá aceptar expresamente para poder disfrutarlos.
				</p>
				<p>Para registrarse en la Plataforma, el Usuario deberá:</p>
				<p>
					Bajo ningún concepto el Propietario se responsabilizará de la veracidad de los datos de
					registro facilitados por los Usuarios al agente o a través de la Plataforma, por lo que
					cada uno de los Usuarios será único responsable de las posibles consecuencias, errores y
					fallos que pudieran derivarse de la falta de calidad de los datos.
				</p>
				<p>
					En el caso de haber un error en sus datos de registro, el Usuario deberá ponerse en
					contacto con el Propietario en la mayor brevedad posible con el único objeto de llevar a
					cabo las correcciones necesarias.
				</p>
				<p>
					Del mismo modo, el Usuario registrado asume que la cuenta de Usuario es personal e
					intransferible, pudiendo registrarse en la Plataforma tanto personas físicas como personas
					jurídicas, ya sean sociedades mercantiles u otro tipo de entidades. Dicho lo anterior, es
					responsabilidad del Usuario guardar con la debida diligencia el Usuario y la contraseña,
					no pudiendo ser facilitada a terceros en ningún momento. En consecuencia, los Usuarios son
					responsables de la adecuada custodia y confidencialidad de cualesquiera identificadores
					y/o contraseñas que hayan seleccionado como Usuarios registrados de El Propietario, y se
					comprometen a no ceder su uso a terceros, ya sea temporal o permanente, ni permitir su
					acceso a personas ajenas. Por ello, el incumplimiento de dicho hecho por parte del Usuario
					eximirá a El Propietario con respecto a la responsabilidad de los daños y perjuicios que
					haya podido ocasionar al Usuario con respecto al acceso no autorizado. . Será
					responsabilidad del Usuario la utilización ilícita de la Plataforma por cualquier tercero
					ilegítimo, que emplee a tal efecto una contraseña a causa de una utilización no diligente
					o de la pérdida de la misma por el Usuario, eximiendo de responsabilidad a El Propietario
				</p>
				<p>
					La contraseña, personal e intransferible, deberá ser generada por el Usuario de acuerdo a
					las reglas de robustez y complejidad que se establezcan en cada momento por El
					Propietario. La contraseña creada por el Usuario tendrá una validez temporal ilimitada.
				</p>
				<p>
					No obstante, El Propietario dispone de funcionalidades necesarias para que el Usuario,
					notificándoselo previamente a El Propietario, pueda cambiar su contraseña cuando lo
					considere oportuno, por ejemplo, porque sospeche o constante que se haya producido la
					quiebra de la confidencialidad de la contraseña.
				</p>
				<p>
					La contraseña tendrá carácter personal e intransferible. El Usuario se compromete a hacer
					un uso diligente de su contraseña y mantenerla en secreto, no transmitiéndola a ningún
					tercero y tampoco a El Propietario En consecuencia, los Usuarios son responsables de la
					adecuada custodia y confidencialidad de cualesquiera identificadores y/o contraseñas que
					hayan seleccionado como Usuarios registrados de El Propietario, y se comprometen a no
					ceder su uso a terceros, ya sea temporal o permanente, ni permitir su acceso a personas
					ajenas. Será responsabilidad del Usuario la utilización ilícita dela Plataforma por
					cualquier tercero ilegítimo, que emplee a tal efecto una contraseña a causa de una
					utilización no diligente o de la pérdida de la misma por el Usuario, eximiendo de
					responsabilidad a El Propietario
				</p>
				<p>
					En virtud de lo anterior, es obligación del Usuario notificar de forma inmediata a El
					Propietario S.L. acerca de cualquier hecho que permita el uso indebido de los
					identificadores y/o contraseñas, tales como el robo, extravío, o el acceso no autorizado a
					los mismos, con el fin de proceder a su inmediata cancelación. Mientras no se comuniquen
					tales hechos, El Propietario S.L. quedará eximido de cualquier responsabilidad que pudiera
					derivarse del uso indebido de los identificadores o contraseñas por terceros no
					autorizados.
				</p>
				<h3 className="text-2xl font-extrabold">PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>
				<p>
					La totalidad del contenido de la Plataforma: texto, imágenes, marcas, gráficos, logotipos,
					botones, archivos de software, combinaciones de colores, así como la estructura,
					selección, ordenación y presentación de sus contenidos, se encuentra protegida por las
					leyes sobre Propiedad Intelectual e Industrial, quedando prohibida su reproducción,
					distribución, comunicación pública y transformación, salvo para uso personal y privado.
				</p>
				<p>
					El Propietario no garantiza que los contenidos sean precisos o libres de error o que el
					libre uso de los mismos por el Usuario no infrinja los derechos de terceras partes. El
					buen o mal uso de esta Plataforma y de sus contenidos será de la exclusiva responsabilidad
					del usuario.
				</p>
				<p>
					Asimismo, queda prohibida la reproducción, retransmisión, copia, cesión o redifusión,
					total o parcial, de la información contenida en la Plataforma, cualquiera que fuera su
					finalidad y el medio utilizado para ello, sin autorización previa de El Propietario
				</p>
				<p>
					El Propietario garantiza que es el legítimo titular de la Plataforma y que tiene las
					autorizaciones necesarias.
				</p>
				<p>
					El Usuario reconoce expresamente que El Propietario ostenta todo derecho, título e interés
					sobre la Plataforma o las autorizaciones necesarias para su uso y las páginas o sitios web
					asociadas a éste, así como sobre todos sus módulos, modificaciones y actualizaciones y
					sobre cualquier elemento y/o funcionalidad que fuera desarrollada sobre el mismo.
				</p>
				<p>
					A estos efectos, se incluye sin limitación alguna el reconocimiento de la titularidad de
					El Propietario de todos los derechos de autor, propiedad intelectual y/o industrial,
					pudiendo explotar la Plataforma, sin restricción alguna de naturaleza temporal,
					territorial, relativa a medios de difusión o modalidades de explotación, y sin más
					limitaciones que las establecidas en las leyes, así como todos los contenidos publicados
					en el mismo. No obstante, los logotipos de los productos comercializados son propiedad
					exclusiva de sus respectivos titulares, contando El Propietario con la autorización previa
					y necesaria para su utilización.
				</p>
				<p>
					La estructura, características, códigos, métodos de trabajo, sistemas de información e
					intercambio de la misma, plataformas de desarrollo, know-how, metodologías, procesos,
					tecnologías o algoritmos que constituyan y/o puedan constituir la Plataforma, son
					propiedad exclusiva de El Propietario, encontrándose debidamente protegidos por las leyes
					nacionales e internacionales de propiedad intelectual y/o industrial, no pudiendo ser
					objeto de ulterior modificación, copia, alteración, reproducción, adaptación o traducción
					por parte del Usuario sin previo consentimiento expreso por parte de El Propietario
				</p>
				<p>
					La puesta a disposición de la Plataforma o el mero acceso por parte del Usuario no
					implica, en ningún caso, la cesión de su titularidad ni la concesión de un derecho de uso
					a favor del Usuario distinto del expresado en la presente cláusula.
				</p>
				<p>
					Con el fin de que el Usuario pueda utilizar la Plataforma, propiedad exclusiva de El
					Propietario S.L., ésta cede una licencia de uso a favor del Usuario de carácter no
					exclusivo, de ámbito temporal limitado a la duración del presente contrato, de ámbito
					espacial ilimitado en la medida en que puede accederse desde cualquier equipo o
					dispositivo con acceso a Internet, revocable y no sublicenciable.
				</p>
				<p>
					En cualquier caso, queda absolutamente prohibida cualquier tipo de reproducción,
					imitación, transmisión, traducción, modificación, elaboración de obra derivada y/o
					comunicación pública, con independencia del medio empleado para ello, asumiendo en caso
					contrario el Usuario o tercero infractor todas las responsabilidades directas o derivadas
					que pudieran llegar a producirse.
				</p>
				<p>
					Para cualquier aspecto que no se encuentre expresamente reconocido en el presente Aviso
					Legal, se entenderán reservados todos los derechos a favor de El Propietario, siendo
					necesaria la autorización escrita por parte de ésta para poder llevarlo a cabo.
				</p>
				<h3 className="text-2xl font-extrabold">RESPONSABILIDADES</h3>
				<p>El Propietario no será responsable directa ni subsidiariamente de:</p>
				<ul>
					<li>
						La calidad del servicio, la velocidad de acceso, el correcto funcionamiento ni la
						disponibilidad ni continuidad de funcionamiento de la Plataforma.
					</li>
					<li>
						Los daños que puedan causarse en los equipos del Usuario por la utilización de la
						Plataforma.
					</li>
					<li>
						Los casos en que un tercero, quebrantando las medidas de seguridad establecidas, acceda
						a los mensajes o los utilice para la remisión de virus informáticos.
					</li>
					<li>
						Los vicios y defectos de toda clase de los contenidos transmitidos, difundidos,
						almacenados o puestos a disposición.
					</li>
					<li>
						La licitud, fiabilidad y utilidad de los contenidos que los usuarios transmitan con la
						utilización de la Plataforma o de los servicios en él ofertados, así como tampoco su
						veracidad o exactitud. El Propietario no controla la utilización que los usuarios hacen
						de la Plataforma, ni garantiza que lo hagan conforme a lo establecido en el presente
						Aviso Legal.
					</li>
				</ul>
				<p>A título enunciativo y con carácter no limitativo, el Usuario será responsable de:</p>
				<ul>
					<li>
						los contenidos introducidos por ellos, especialmente de los datos e informaciones
						introducidos y enviados a El Propietario por medio de la Plataforma.
					</li>
					<li>
						la realización de cualquier tipo de actuación ilícita, lesiva de derechos, nociva y/o
						perjudicial.
					</li>
					<li>la facilitación de datos no veraces o incorrectos.</li>
				</ul>
				<p>
					El Propietario no puede garantizar la fiabilidad, utilidad o veracidad de absolutamente
					toda la información y/o de los servicios de la Plataforma, ni tampoco de la utilidad o
					veracidad de la documentación puesta a disposición a través de la misma.
				</p>
				<p>
					En consecuencia, El Propietario no garantiza ni se hace responsable de: (i) la continuidad
					de los contenidos de la Plataforma; (ii) la ausencia de errores en dichos contenidos;
					(iii) la ausencia de virus y/o demás componentes dañinos en la Plataforma o en el servidor
					que lo suministra; (iv) la invulnerabilidad de la Plataforma y/o la imposibilidad de
					vulnerar las medidas de seguridad que se adopten en el mismo; (v) la falta de utilidad o
					rendimiento de los contenidos de la Plataforma; y (vi) los daños o perjuicios que cause, a
					sí mismo o a un tercero, cualquier persona que infringiera las condiciones, normas e
					instrucciones que GIBOS S.L. establece en la Plataforma o a través de la vulneración de
					los sistemas de seguridad de la misma.
				</p>
				<p>
					Ello no obstante, El Propietario declara que ha adoptado todas las medidas necesarias,
					dentro de sus posibilidades y del estado de la técnica, para garantizar el funcionamiento
					de la Plataforma y reducir al mínimo los errores del sistema, tanto desde el punto de
					vista técnico como de los contenidos publicados en la misma.
				</p>
				<p>
					El Propietario no garantiza la licitud, fiabilidad y utilidad de los contenidos
					suministrados por terceros a través de la Plataforma. Si el Usuario tuviera conocimiento
					de la existencia de algún contenido ilícito, ilegal, contrario a las leyes o que pudiera
					suponer una infracción de derechos de terceros, deberá notificarlo inmediatamente a El
					Propietario para que ésta pueda proceder a la adopción de las medidas oportunas.
				</p>
				<p>
					El Propietario no será responsable de la veracidad, integridad o actualización de las
					informaciones publicadas en la Plataforma provenientes de fuentes ajenas al mismo, así
					como tampoco de las contenidas en otras webs a las que se enlace desde la Plataforma. El
					Propietario no asumirá responsabilidad en cuanto a hipotéticos perjuicios que pudieran
					originarse por el uso de las citadas informaciones.
				</p>
				<p>
					En todo caso, El Propietario se reserva el derecho a suspender, modificar, restringir o
					interrumpir, ya sea temporal o permanentemente, el acceso, navegación, uso, alojamiento
					y/o descarga del contenido y/o uso de servicios de la Plataforma, con o sin previa
					notificación, a los usuarios que contravengan cualquiera de las disposiciones detalladas
					en el presente Aviso Legal, sin que medie la posibilidad del Usuario de exigir
					indemnización alguna por esta causa.
				</p>
				<p>
					Asimismo, El Propietario no asume responsabilidad alguna respecto de servicios y contenido
					de terceros a los que pueda acceder a través de la Plataforma, incluyendo de las
					situaciones de solvencia patrimonial del Usuario, así como de las condiciones de los
					créditos hipotecario, debido a que dichas ofertas no son elaboradas por El Propietario y,
					por tanto, no son su responsabilidad.
				</p>
				<p>
					Por último, con respecto a los servicios prestados por El Propietario el usuario asume
					que, en cualquier caso, las decisiones adoptadas en materia de intermediación hipotecaria
					son de su única responsabilidad y riesgo, limitándose El Propietario a prestar un servicio
					de intermediación y/o asesoramiento utilizando una fórmula propia que averigua la
					viabilidad o de la operación para el usuario y su situación económica concreta, basándose
					en los datos facilitados por el usuario. En todo caso la decisión de asumir o no la
					operación será exclusiva del usuario.
				</p>
				<h3 className="text-2xl font-extrabold">PROCEDIMIENTO DE CONTRATACIÓN</h3>
				<p>
					Para el caso de que el Usuario quiera acceder a los servicios ofrecidos en la Plataforma,
					el procedimiento de contratación se lleva a cabo de forma completamente electrónica a
					través de la misma.
				</p>
				<p>
					El procedimiento completo que deberán seguir todos los Usuarios que deseen contratar los
					servicios de El Propietario, será el siguiente:
				</p>
				<ul>
					<li>
						El Usuario deberá leer atentamente el presente Aviso Legal y Condiciones de Uso de la
						Plataforma, la Política de Privacidad, y la Información Previa exigida por la Ley
						2/2009, de 31 de marzo, por la que se regula la contratación con los consumidores de
						préstamos o créditos hipotecarios y de servicios de intermediación para la celebración
						de contratos de préstamo o crédito.
					</li>
					<li>
						Una vez leído los anteriores documentos publicados en la Plataforma, el Usuario -en caso
						de interés de contratar servicios de intermediación con El Propietario- deberá proceder
						al proceso de registro de la Plataforma o a través de un tercero (agente).
					</li>
					<li>
						Una vez el Usuario haya procedido al registro como usuario de El Propietario facilitando
						sus datos identificativos (nombre, apellidos y DNI) y sus datos de contacto (número de
						teléfono y correo electrónico) - o a través del agente -, se le facilitará un link a la
						Plataforma en el cual el Usuario, para acceder a los servicios de El Propietario, deberá
						aceptar las condiciones de contratación (contrato de intermediación). En el caso de no
						aceptar las mismas, no podrá acceder a los servicios anteriormente indicados y
						descritos.
					</li>
				</ul>
				<p>
					Una vez el Usuario acepta el Contrato de Intermediación, se entiende que la relación
					contractual entre El Propietario y el Usuario está perfeccionada.
				</p>
				<h3 className="text-2xl font-extrabold">BAJA COMO USUARIO REGISTRADO</h3>
				<p>
					El Usuario puede, en cualquier momento, solicitar su baja de la Plataforma, bastando para
					ello solicitarlo mediante comunicación escrita a El Propietario a su domicilio social
					indicado en el encabezamiento del presente documento, indicando su nombre de Usuario y el
					servicio concreto del que desee darse de baja. Tenga en cuenta que la baja como Usuario de
					la Plataforma puede suponer la imposibilidad por parte de El Propietario de prestarle los
					servicios de intermediación de crédito hipotecario.
				</p>
				<p>
					En cualquier caso, una vez efectuada la baja, el Usuario podrá solicitar un nuevo
					registro, quedando a salvo la facultad de El Propietario de no admitir dicho registro.
				</p>
				<h3 className="text-2xl font-extrabold">FUERO</h3>
				<p>
					El presente Aviso Legal se encuentra redactado en castellano y sometido a la legislación
					española vigente. Para cualquier tipo de controversia derivada de la utilización de los
					servicios ofrecidos o de los contenidos propios de la Plataforma, las partes se someterán
					a los Tribunales y Juzgados competentes de Madrid.
				</p>
				<p>
					Si alguna de las cláusulas descritas en el Aviso Legal fuese declarada nula y sin efecto,
					continuarán siendo de aplicación las restantes, sin que deban quedar afectadas por la
					declaración de nulidad.
				</p>
				<p>Última actualización: Abril de 2022</p>
			</div>
		</>
	);
};
