import Image from 'next/image';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import { AdminMenu } from '../../../dtos';
import { FunctionComponent } from 'react';

type Props = {
	menu: AdminMenu[];
};

const DashboardMenu: FunctionComponent<Props> = ({ menu }: Props) => {
	const router = useRouter();
	const path = router.asPath;

	const menuElements = menu.map((item, index) => {
		const isActive = path === '/area-privada' ? path === item.path : item.path.includes(path);

		let className;
		if (isActive) {
			className =
				'text-blue-500 bg-gradient-to-r from-white to-blue-100 border-r-4 border-blue-500 dark:from-gray-700 dark:to-gray-800';
		} else {
			className = 'text-gray-500 dark:text-gray-200 hover:text-blue-500';
		}

		return (
			<Link
				href={item.path}
				key={index}
				className={`w-full font-thin uppercase flex items-center p-4 my-2 transition-colors duration-200 justify-start ${className}`}>
				<span className="text-left">
					<FontAwesomeIcon icon={item.icon} className="h-4 w-4 m-1" />
				</span>
				<span className="mx-4 text-sm font-normal">{item.title}</span>
			</Link>
		);
	});

	return (
		<div className="h-screen hidden lg:block m-4 shadow-lg w-80">
			<div className="bg-white rounded-2xl dark:bg-gray-700">
				<div className="flex items-center justify-center pt-6">
					<Link href="/" className="dark:hidden">
						<Image
							src="/images/logos/logo.png"
							className="h-10 w-8 object-contain"
							height={35}
							width={100}
							alt="cooches"
						/>
					</Link>
					<Link href="/" className="hidden dark:block">
						<Image
							src="/images/logos/logo-white.png"
							className="h-10 w-8 object-contain"
							height={35}
							width={100}
							alt="cooches"
						/>
					</Link>
				</div>
				<nav className="mt-6">
					<div>{menuElements}</div>
				</nav>
			</div>
		</div>
	);
};

export default DashboardMenu;
