import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import axios from 'axios';
import { FormEvent, FunctionComponent, useState } from 'react';
import { faComment, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { StaticImport } from '../news/news-card';

type PageProps = {
	image: string | StaticImport;
};

export const ContactComponent: FunctionComponent<PageProps> = ({ image }: PageProps) => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [showMenssageSend, setShowMenssageSend] = useState(false);

	const sendContact = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		axios
			.post('/api/utils/contact', {
				name,
				email,
				message
			})
			.then(() => {
				setShowMenssageSend(true);

				setTimeout(() => {
					setShowMenssageSend(false);
				}, 5000);
			});

		return false;
	};

	const menssageSendComponent = showMenssageSend ? (
		<div
			id="toast-simple"
			className="flex absolute z-50 items-center p-4 space-x-4 w-full max-w-xs text-gray-500 bg-white rounded-lg divide-x divide-gray-200 shadow dark:text-gray-200 dark:divide-gray-700 space-x dark:bg-gray-600"
			role="alert">
			<FontAwesomeIcon icon={faEnvelope} className="mx-auto object-cover h-9 w-9 " />
			<div className="pl-4 text-sm font-normal">Mensaje enviado con éxito</div>
		</div>
	) : undefined;

	return (
		<>
			{menssageSendComponent}
			<div className="sm:w-2/4 lg:w-2/4 flex flex-col relative z-20">
				<section>
					<form className="container max-w-2xl mx-auto shadow-md md:w-3/4" onSubmit={sendContact}>
						<div className="p-4 bg-gray-100 border-t-2 border-green-600 bg-opacity-5 dark:bg-white rounded-t-lg">
							<div className="max-w-sm mx-auto md:w-full md:mx-0">
								<div className="inline-flex items-center space-x-4">
									<FontAwesomeIcon icon={faComment} className="mx-auto object-cover h-9 w-9 " />
									<h1 className="text-gray-600">Formulario de contacto</h1>
								</div>
							</div>
						</div>
						<div className="space-y-6 bg-white">
							<div className="items-center w-full p-4 space-y-4 text-gray-500 md:inline-flex md:space-y-0">
								<h2 className="max-w-sm mx-auto md:w-1/3">Datos</h2>
								<div className="max-w-sm mx-auto space-y-5 md:w-2/3">
									<div className=" relative ">
										<input
											name="name"
											type="text"
											className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
											placeholder="Nombre"
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</div>
									<div className=" relative ">
										<input
											name="email"
											type="email"
											className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
											placeholder="Email"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</div>
								</div>
							</div>
							<hr />
							<div className="items-center w-full p-4 space-y-4 text-gray-500 md:inline-flex md:space-y-0">
								<h2 className="max-w-sm mx-auto md:w-1/3">Mensaje</h2>
								<div className="max-w-sm mx-auto space-y-5 md:w-2/3">
									<div>
										<div className=" relative ">
											<textarea
												name="message"
												className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
												placeholder="Mensaje"
												value={message}
												onChange={(e) => setMessage(e.target.value)}
											/>
										</div>
									</div>
								</div>
							</div>
							<hr />
							<div className="w-full px-4 pb-4 ml-auto text-gray-500 md:w-1/3">
								<button
									type="submit"
									className="py-2 px-4 bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
									Enviar
								</button>
							</div>
						</div>
					</form>
				</section>
			</div>
			<div className="hidden sm:block sm:w-1/3 lg:w-3/5 relative">
				<Image src={image} alt="Cooches - Estamos trabajando en ello" />
			</div>
		</>
	);
};
