import { createDirectus, rest, graphql, staticToken, readItems } from '@directus/sdk';

export type Position = {
	type: string;
	coordinates: number[];
};

export type Web = {
	id: string;
	code: string;
	name: string;
	hasLogin: boolean;
	port: number;
	logo: string;
	reversedLogo: string;
	title: string;
	description: string;
	keywords: string[];
};

export type Menu = {
	id: string;
	name: string;
	link: string;
	web: Web;
	sort: number;
};

export type News = {
	id: string;
	date_created: string;
	date_updated: string;
	name: string;
	code: string;
	date: string;
	shortDescription: string;
	tags: string[];
	category: NewsCategory;
	web: Web;
	image: string;
	content: string;
	status: 'published' | 'draft' | 'archived';
	products: Product[];
};

export type Product = {
	id: string;
	date_created: string;
	date_updated: string;
	name: string;
	web: Web;
	code: string;
	price: string;
	old_price: number;
	stars: number;
	discount: number;
	photo: string;
	link: string;
	status: 'published' | 'draft' | 'archived';
};

export type NewsCategory = {
	id: string;
	name: string;
	code: string;
	color: string;
	web: Web;
};

export type BaseSchema = {
	webs: Web[];
	menus: Menu[];
	news: News[];
	newsCategory: NewsCategory[];
	products: Product[];
};

export const db = <T>() => {
	const client = createDirectus<T & BaseSchema>('https://cms.altairstudios.es')
		.with(rest())
		.with(graphql())
		.with(staticToken('uRGMXWe54sTkRTYr-cOYFtbd9p4RXHvm'));

	return client;
};

export type CdnType = {
	height?: number;
};

export const cdn = (assetId: string, options?: CdnType) => {
	const query = ['access_token=S3FEb5fj-1GnHbXFdSBFBgJKLTfM1C7A'];

	if (options?.height) {
		query.push(`height=${options.height}`);
	}

	return `https://cms.altairstudios.es/assets/${assetId}?${query.join('&')}`;
};

export const data = () => {
	const cms = db();

	return {
		news: () => {
			return {
				getLastNews: async (last?: number) => {
					if (!last) {
						last = 3;
					}

					const news = await cms.request(
						readItems('news', {
							fields: ['*', { category: ['*'] }],
							limit: last,
							sort: '-date',
							filter: {
								status: { _eq: 'published' },
								web: {
									code: { _eq: process.env.WEB_CODE }
								}
							}
						})
					);

					return news;
				},
				getNews: async () => {
					const news = await cms.request(
						readItems('news', {
							fields: ['*', { category: ['*'] }],
							sort: '-date',
							filter: {
								status: { _eq: 'published' },
								web: {
									code: { _eq: process.env.WEB_CODE }
								}
							}
						})
					);

					return news;
				},
				getANews: async (newsCode: string) => {
					const aNew = await cms.request(
						readItems('news', {
							fields: ['*', { category: ['*'] }],
							filter: {
								code: { _eq: newsCode },
								web: {
									code: { _eq: process.env.WEB_CODE }
								}
							}
						})
					);

					return aNew[0];
				}
			};
		}
	};
};
