import { FunctionComponent, PropsWithChildren } from 'react';
import { AdminMenu } from '../../dtos';
import DashboardMenu from './components/menu';

type PageProps = {
	menu: AdminMenu[];
};

export const AdminDashboardPage: FunctionComponent<PageProps & PropsWithChildren> = ({
	menu,
	children = undefined
}: PageProps & PropsWithChildren) => {
	return (
		<main className="w-full dark:bg-gray-800 rounded-2xl mb-16">
			<div className="flex items-start justify-between">
				<DashboardMenu menu={menu} />
				<div className="flex flex-col w-full pl-0 md:p-4 md:space-y-4">
					<div className="pb-24 pt-2 pr-2 pl-2 md:pt-0 md:pr-0 md:pl-0">
						<div className=" ">
							<div className="w-full">{children}</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};
