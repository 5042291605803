import { FunctionComponent } from 'react';
import Link from 'next/link';

type PageProps = {
	name: string;
};

export const CookieDisclaimerComponent: FunctionComponent<PageProps> = ({ name }: PageProps) => {
	return <>
        <div className="flex flex-col text-black dark:text-white">
            <h2 className="text-4xl font-extrabold">Política de cookies</h2>
            <p>
                Le informamos que, en cumplimiento de la normativa vigente en materia de protección de
                datos, este sitio web utiliza cookies propias y de terceros.
            </p>
            <h3 className="text-2xl font-extrabold">1. ¿Qué es una cookie?</h3>
            <p>
                Una cookie es un fichero que se descarga en el dispositivo del usuario cuando se accede a
                la página web, con la finalidad de almacenar y recuperar información sobre la navegación
                que se realiza en el dispositivo.
            </p>

            <h3 className="text-2xl font-extrabold"> 2. Tipos de cookies</h3>
            <p>2.1. Según la entidad que las gestione</p>
            <p>
                Cookies propias: Son aquéllas que se envían al equipo terminal del usuario desde un equipo
                o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado
                por el usuario.
            </p>
            <p>
                Cookies de tercero: Son aquellas que se envían al equipo terminal del Usuario desde un
                equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los
                datos obtenidos a través de las cookies.
            </p>

            <p>
                {' '}
                Load-balancing cookies: Se usan para recabar datos que ayudan a regular, dividir y
                gestionar la congestión de los servidores. Se consideran necesarias para la comunicación a
                través de la red. Únicamente identifican un servidor, por lo tanto, no contienen datos
                personales.
            </p>
            <p>
                {' '}
                Cookies para customizar la interfaz de usuario: Se usan, entre otras, para indicar el
                idioma preferido o el formato de contenido. No requieren ningún identificador particular,
                por lo tanto, no constituirán información personal.
            </p>
            <p> 2.2. Según el tiempo que la cookie permanece activa en el equipo</p>
            <p>
                {' '}
                Cookies de sesión: Son cookies temporales que permanecen en el archivo de cookies de su
                navegador hasta que abandone la página web, por lo que ninguna queda registrada en el
                disco duro del usuario. La información obtenida por medio de estas cookies sirve para
                analizar las pautas de tráfico de la web. A la larga, esto nos permite proporcionar una
                mejor experiencia para mejorar el contenido y facilitar su uso.
            </p>
            <p>
                {' '}
                Cookies permanentes: Son almacenadas en el disco duro y nuestra web las lee cada vez que
                el usuario realiza una nueva visita. Una cookie permanente puede ser accedida y tratada
                durante un periodo definido por el responsable de la cookie, después de esa fecha dejará
                de funcionar.
            </p>
            <p> 2.3. Según su finalidad</p>
            <p>
                Cookies técnicas: Permiten al usuario la navegación a través de una página web, plataforma
                o aplicación y la utilización de las diferentes opciones o servicios que en ella existan.
            </p>
            <p>
                Cookies de personalización: Permiten al usuario acceder al servicio con algunas
                características de carácter general predefinidas en función de una serie de criterios en
                el terminal del usuario como, por ejemplo, el idioma, el tipo de navegador a través del
                cual accede al servicio, la configuración regional desde donde accede al servicio, etc.
            </p>
            <p>
                Cookies de análisis: Permiten al responsable de las mismas el seguimiento y análisis del
                comportamiento de los usuarios de los sitios web a los que están vinculadas. La
                información recogida mediante este tipo de cookies se utiliza en la medición de la
                actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de
                navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de
                introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del
                servicio.
            </p>
            <p>
                {' '}
                Cookies publicitarias: Permiten la gestión, de la forma más eficaz posible, de los
                espacios publicitarios que, en su caso, el editor haya incluido en una página web,
                aplicación o plataforma desde la que presta el servicio solicitado en base a criterios
                como el contenido editado o la frecuencia en la que se muestran los anuncios.
            </p>
            <p>
                {' '}
                Cookies de publicidad comportamental: Estas cookies almacenan información del
                comportamiento de los usuarios obtenida a través de la observación continuada de sus
                hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar
                publicidad en función del mismo.
            </p>

            <h3 className="text-2xl font-extrabold">
                3. ¿Qué tipo de cookies se utilizan en esta página web?
            </h3>
            <p>
                {name} hace uso de cookies propias y de terceros, en concreto, complementos externos de
                Google. Su uso implica la remisión de cookies. Para más información consulte:{' '}
                <Link href="http://www.google.com/intl/es/policies/technologies/cookies/">
                    Cómo utiliza Google las cookies
                </Link>
            </p>
            <p>
                La información que recogen los complementos externos de Google en nuestro Sitio Web,
                incluyendo la dirección IP del usuario, puede ser transmitida a Google y almacenada en sus
                servidores. Esta información es gestionada enteramente por Google. Estas cookies no
                identifican al usuario personalmente a menos que tenga activa una sesión en Google,
                quedando en ese caso vinculado con dicha cuenta de Google.
            </p>
            <p>
                Para más información consulte:{' '}
                <Link href="http://www.google.com/intl/es/policies/technologies/types/">
                    Tipos de cookies que utiliza Google
                </Link>{' '}
                y la Política de Privacidad de Google:{' '}
                <Link href="https://www.google.com/intl/es_es/policies/privacy">
                    Política de privacidad
                </Link>
            </p>

            <p>
                {' '}
                En la siguiente tabla explicamos las cookies que se utilizan en esta página web y por qué:
            </p>
            <p>
                <strong>Cookies necesarias</strong>: Ayudan a hacer una página web utilizable activando
                funciones básicas como la navegación en la página y el acceso a áreas seguras de la página
                web. La página web no puede funcionar adecuadamente sin estas cookies.
            </p>

            <p>
                {name}: propia
                <br />
                Google:{' '}
                <Link href="https://policies.google.com/technologies/cookies?hl=es">
                    Más información
                </Link>{' '}
            </p>
            <p>
                <strong>Cookies de preferencias</strong>: Permiten a la página web recordar información
                que cambia la forma en que la página se comporta o el aspecto que tiene, como su idioma
                preferido o la región en la que usted se encuentra.
            </p>
            <p>{name}: propia</p>
            <p>
                <strong>Cookies estadísticas</strong>: Ayudan a los propietarios de páginas web a
                comprender cómo interactúan los visitantes con las páginas web reuniendo y proporcionando
                información de forma anónima.
            </p>
            <p>
                Google:{' '}
                <Link href="https://policies.google.com/technologies/cookies?hl=es">
                    Más información
                </Link>
            </p>
            <p>
                <strong>Cookies de marketing</strong>: se utilizan para rastrear a los visitantes en las
                páginas web. La intención es mostrar anuncios relevantes y atractivos para el usuario
                individual, y por lo tanto, más valiosos para los editores y terceros anunciantes.
            </p>
            <p>
                Google:{' '}
                <Link href="https://policies.google.com/technologies/cookies?hl=es">
                    Más información
                </Link>
            </p>

            <h3 className="text-2xl font-extrabold">4. ¿Quién utiliza las cookies?</h3>
            <p>
                La información obtenida a través de las Cookies será incorporada a los tratamientos de El
                Propietario con las finalidades y plazos indicados en la presente Política.
            </p>
            <p>
                Asimismo, esta información también se incorporará a los ficheros de las empresas
                proveedoras de las Cookies de Terceros indicadas en el presente documento, siendo tratada
                con las finalidades indicadas en sus respectivas políticas de privacidad.
            </p>
            <p>
                La base jurídica que legitima estos tratamientos es el consentimiento del usuario,
                prestado a través de las opciones de configuración de cookies; no obstante, el usuario
                podrá rechazar el uso de Cookies mediante los mecanismos indicados a continuación.
            </p>
            <h3 className="text-2xl font-extrabold">5. ¿Cómo cambio mi configuración de cookies?</h3>
            <p>
                Puede revisar su configuración (permitir, bloquear o eliminar las cookies instaladas) en
                su equipo mediante la configuración de las opciones del navegador instalado en su
                ordenador y mediante la opción “gestionar consentimiento” que se le ofrece, como una
                pequeña ventana emergente abajo a la izquierda de la pantalla, durante la navegación. Por
                favor, lea atentamente la sección de ayuda de su navegador para conocer más acerca de cómo
                activar el “modo privado” o desbloquear determinadas cookies.
            </p>

            <ul>
                <li>
                    Microsoft Explorer: Puede consultar el
                    <Link href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">
                        soporte de Microsoft
                    </Link>
                    o la Ayuda del navegador.
                </li>
                <li>
                    Microsoft Edge: Puede consultar el
                    <Link href="https://support.microsoft.com/es-es/help/4027947/microsoft-edge-delete-cookies">
                        soporte de Microsoft
                    </Link>
                    o la Ayuda del navegador.
                </li>
                <li>
                    Firefox: Puede consultar el
                    <Link href="https://support.mozilla.org/es/kb/Borrar%2520cookies">
                        soporte de Mozilla
                    </Link>
                    o la Ayuda del navegador.
                </li>
                <li>
                    Chrome: Puede consultar el
                    <Link href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&amp;hl=es">
                        soporte de Google
                    </Link>
                    o la Ayuda del navegador.
                </li>
                <li>
                    Safari: Puede consultar el
                    <Link href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">
                        soporte de Apple
                    </Link>
                    o la Ayuda del navegador.
                </li>
            </ul>
            <h3 className="text-2xl font-extrabold">
                6. Actualizaciones y cambios en la Política de Cookies
            </h3>
            <p>
                La variación de las cookies que aparezcan en esta web puede no estar relacionada con la
                gestión y el mantenimiento de la misma, por lo que se llevan a cabo revisiones periódicas
                para adecuar la política de cookies.
            </p>
            <p>
                El titular de la web no asume ninguna responsabilidad por problemas legales o técnicos
                causados por el incumplimiento por parte del usuario de las recomendaciones incluidas y no
                se hace responsable del contenido y veracidad de las políticas de privacidad de terceros.
            </p>
            <p>
                Esta Política de Cookies podrá ser modificada en función de exigencias legislativas,
                reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones dictadas
                por la Agencia Española de Protección de Datos, por ello se aconseja a los Usuarios que la
                visiten periódicamente.
            </p>

            <p>Última actualización: Abril de 2022</p>
        </div>
    </>;
};
