import Link from 'next/link';
import { FunctionComponent } from 'react';

type PageProps = {
	domain: string;
};

export const PrivacyDisclaimerComponent: FunctionComponent<PageProps> = ({ domain }: PageProps) => {
	return (
		<>
			<div className="flex flex-col text-black dark:text-white">
				<h2 className="text-4xl font-extrabold">Política de privacidad</h2>

				<p>
					Juan Benavides Romero, (en adelante, El Propietario) conforme a la legislación vigente en
					materia de Protección de Datos de Carácter Personal, pone en conocimiento de los usuarios
					de la página web {domain} (en adelante, la Página) la Política de Privacidad y Protección
					de Datos que aplicará en el tratamiento de los datos personales que el Usuario facilite
					voluntariamente al acceder a su web.
				</p>
				<p>
					El Usuario, al proporcionar a El Propietario sus datos de carácter personal a través de
					los formularios electrónicos de la Web, apartado de contacto, consiente expresamente que
					El Propietario pueda tratar esos datos en los términos de esta cláusula de Política de
					Privacidad y Protección de Datos y para los fines aquí expresados.
				</p>
				<p>
					El Propietario pone en conocimiento de los usuarios de la Página, que sus datos de
					carácter personal sólo podrán obtenerse para su tratamiento cuando sean adecuados,
					pertinentes y no excesivos en relación con el ámbito y las finalidades determinadas,
					explícitas y legítimas para las que se hayan obtenido. Serán cancelados cuando hayan
					dejado de ser necesarios o pertinentes para dicha finalidad o cuando lo solicite el
					titular en el ejercicio de su derecho de cancelación.
				</p>
				<p>
					El Propietario manifiesta su compromiso de cumplir con la legislación vigente en cada
					momento en materia de protección de datos.
				</p>
				<p>
					Los datos personales facilitados serán tratados por la Compañía y se utilizarán de acuerdo
					con la siguiente información.
				</p>

				<h3 className="text-2xl font-extrabold">
					¿Qué datos de carácter personal recabamos y para qué los utilizamos?
				</h3>
				<ul>
					<li>
						Datos recabados de manera directa: por la cumplimentación voluntaria del formulario de
						contacto e interacción en nuestras Redes sociales.
					</li>
					<li>
						Datos recabados de manera indirecta mediante el uso de cookies: para estadísticas, para
						analizar el uso que se realiza de nuestra Página y facilitar servicios de valor añadido
						como el mapa de ubicación de nuestras oficinas.
					</li>
				</ul>
				<p>
					En la tabla que le mostramos a continuación encontrará información más detallada sobre el
					tratamiento de sus datos de carácter personal, incluyéndose los siguientes epígrafes:
				</p>
				<ul>
					<li>
						Finalidades principales: Se identifica con que finalidad necesitamos recoger sus datos,
						es decir, porqué tratamos su información.
					</li>
					<li>Legitimación: Se detalla el motivo que nos habilita para poder tratar sus datos.</li>
					<li>
						Plazos de conservación: Se identifica el plazo concreto de conservación o los criterios
						que determinan dicho periodo.
					</li>
					<li>
						Destinatarios: Identificamos, en su caso, con quién compartiremos sus datos de forma
						legítima.
					</li>
					<li>
						Derechos de los interesados: Le informamos sobre los derechos que le asisten como
						interesado en el tratamiento de sus datos de carácter personal.
					</li>
				</ul>
				<h3 className="text-2xl font-extrabold">
					INFORMACIÓN SOBRE PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL
				</h3>
				<h4 className="text-xl font-extrabold">Responsable del tratamiento</h4>
				<p>
					Denominación social: Juan Benavides Romero
					<br />
					NIF: 54098996Y
					<br />
					Dirección: Chichón 24, 28723, Pedrezuela, Madrid
					<br />
					Teléfono: 633 63 02 14
					<br />
					Email: juan@altairstudios.es
				</p>

				<h4 className="text-xl font-extrabold">Finalidades</h4>
				<p>
					Formularios de Contacto: atender la solicitud de información demandada a través del
					formulario de contacto.
				</p>
				<p>
					Cookies: mantener el login de usuario registrado, vigencia de cesta de compra,
					proporcionar estadísticas asociadas al uso de la Página, ubicación de nuestras oficinas y
					sistemas antispam (captcha).
				</p>

				<h4 className="text-xl font-extrabold">Legitimación y conservación</h4>
				<p>
					La base para el tratamiento de los datos es el consentimiento prestado por el usuario al
					marcar las casillas de aceptación.
				</p>
				<p>
					Los campos del formulario de contacto marcados con un asterisco (*) tienen la
					consideración de campos necesarios para gestionar el objeto de éste. De no facilitarlos,
					no será posible atender su solicitud.
				</p>
				<p>
					Consulte nuestra política de cookies para obtener información sobre las cookies necesarias
					para el funcionamiento de la web.
				</p>
				<p>
					Los datos de contacto se conservarán durante el tiempo necesario para atender su consulta
					y no se solicite su supresión.
				</p>
				<p>Consulte nuestra Política de Cookies para identificar la vigencia de cada cookie.</p>
				<p>
					Los datos asociados a su interacción en los perfiles corporativos de redes sociales se
					conservarán durante el plazo que usted habilite en dicha red social a sus publicaciones.
				</p>

				<h4 className="text-xl font-extrabold">
					Destinatarios de cesiones y transferencias internacionales
				</h4>
				<p>No se han previsto cesiones de sus datos a terceros.</p>
				<p>
					Hacemos uso de los “Servicios en Línea para empresas” de Google con herramientas como
					Google Business, constituyendo su uso una transferencia internacional de datos autorizada
					por la AEPD.
				</p>
				<p>
					Responsables de servicios de analítica web: La Página tiene habilitados los servicios de
					Google Analytics prestados por Google LLC. La interacción con los usuarios por el uso de
					dicha herramienta implica la transferencia internacional de datos, de tipo analítico y
					técnico en relación con la Página.
				</p>
				<p>
					Titulares de redes sociales: El Propietario utiliza redes sociales. La interacción con los
					usuarios por el uso de dichos perfiles o herramientas implica la transferencia
					internacional de datos (de tipo analítico y técnico) a los servidores de la red social.
				</p>

				<h4 className="text-xl font-extrabold">Derechos de los interesados</h4>
				<p>
					Puede ejercitar sus derechos de acceso, rectificación, supresión, portabilidad y la
					limitación u oposición dirigiéndose por escrito a la dirección indicada anteriormente.
				</p>
				<p>Los interesados tienen derecho a retirar el consentimiento prestado.</p>
				<p>
					Los interesados tienen derecho a reclamar ante la Autoridad de Control (Agencia Española
					de Protección de Datos: <Link href="https://www.aepd.es">www.aepd.es</Link>
					).
				</p>

				<p>
					El usuario será el único responsable de la veracidad de los datos facilitados a la
					Compañía.
				</p>
				<p>
					La Compañía adopta las medidas de índole técnica y organizativa necesarias para garantizar
					la seguridad de los datos de carácter personal contenidos en los mismos y para evitar su
					alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la
					tecnología, la naturaleza de los datos y los riesgos a los que están expuestos.
				</p>
				<p>
					La Página dispone del Certificado SSL (Secure Socket Layer), con la finalidad de
					garantizar la seguridad en la transmisión de datos entre su navegador y nuestra Página.
				</p>
				<p>
					El servidor seguro establece una conexión de modo que la información se transmite cifrada,
					que aseguran que solo sea inteligible para el dispositivo del usuario y el de la Página.
					De este modo, al utilizar el protocolo SSL se garantiza:
				</p>
				<ul>
					<li>
						Que el usuario está comunicando sus datos al centro servidor de la Página y no a
						cualquier otro
					</li>
					<li>
						Que entre el usuario y la Página los datos se transmiten cifrados, evitando su posible
						lectura o manipulación por terceros.
					</li>
				</ul>
				<p>
					Para verificar que se encuentra en un entorno seguro, el Cliente debe revisar que la
					dirección de la Página de verificación comience por https:// También puede identificar la
					Página como segura cuando aparezca el icono de un candado en la barra de dirección de su
					navegador. Por último, también puede verificar la presencia del certificado SSL
					comprobando las propiedades de la Página en su navegador, para lo cual deberá consultar
					las características del mismo. Cualquier cambio realizado en la Política de Privacidad y
					en las prácticas de administración de la información se reflejará de forma oportuna,
					pudiendo la Compañía agregar, modificar o eliminar dicha política de privacidad cuando lo
					estime necesario.
				</p>

				<p>Última actualización: Abril de 2022</p>
			</div>
		</>
	);
};
