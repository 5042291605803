import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';
import { signOut, useSession } from 'next-auth/react';
import { Menu, Web, cdn } from 'data';

type Props = {
	menu: Menu[];
	web: Web;
	title: string;
};

export const NavbarDesktop = ({ menu, title, web }: Props) => {
	const { data: session, status } = web.hasLogin
		? useSession()
		: { data: undefined, status: undefined };
	const [isLogged] = useState(false);
	const [showAccountMenu, setShowAccountMenu] = useState(false);
	const [timerAccountMenu, setTimerAccountMenu] = useState<NodeJS.Timeout>();
	const router = useRouter();
	const path = router.asPath;

	const navbarStyle =
		'hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium';

	useEffect(() => {});

	const resetHideAccountMenu = () => {
		if (timerAccountMenu) {
			clearTimeout(timerAccountMenu);
		}
	};

	const hideAccountMenu = () => {
		resetHideAccountMenu();
		const timer = setTimeout(() => {
			setShowAccountMenu(false);
		}, 300);

		setTimerAccountMenu(timer);
	};

	let avatar;
	const menus: any[] = [];

	if (!session && web.hasLogin) {
		avatar = <FontAwesomeIcon icon={faUser} className="w-5 h-5" />;

		menus.push(
			<Link
				href="/login"
				key="login"
				className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
				role="menuitem">
				<span className="flex flex-col">
					<span>Login</span>
				</span>
			</Link>
		);
	}

	if (session?.user) {
		avatar = (
			<Image
				src={session.user.image!}
				className="mx-auto object-cover rounded-full h-10 w-10 "
				alt={session.user.name!}
				height={40}
				width={40}
				priority={true}
			/>
		);

		menus.push(
			<Link
				href="/area-privada"
				key="area-privada"
				className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
				role="menuitem">
				<span className="flex flex-col">
					<span>Area privada</span>
				</span>
			</Link>
		);

		menus.push(
			<a
				key="logout"
				href="#"
				className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
				role="menuitem"
				onClick={() => signOut()}>
				<span className="flex flex-col">
					<span>Logout</span>
				</span>
			</a>
		);
	}

	const menuActive = 'text-gray-800 dark:text-gray-100';
	const menuInactive = 'text-gray-400';

	return (
		<>
			<div className=" flex items-center">
				<Link href="/" className="flex-shrink-0">
					<Image
						src={cdn(web.logo, { height: 100 })}
						className="object-contain dark:hidden block"
						height={50}
						width={50}
						alt={web.title}
						title={web.title}
					/>
					<Image
						src={cdn(web.reversedLogo, { height: 100 })}
						className="object-contain dark:block hidden"
						height={50}
						width={50}
						alt={web.title}
						title={web.title}
					/>
				</Link>
				<div className="hidden md:block">
					<div className="ml-10 flex items-baseline space-x-4">
						{menu.map((item) => {
							const isSelected = item.link === '/' ? path === '/' : path.includes(item.link);
							const classHighlight = isSelected ? menuActive : menuInactive;

							return (
								<Link
									href={item.link}
									key={item.link}
									className={`${navbarStyle} ${classHighlight}`}
									title={item.name}>
									{item.name}
								</Link>
							);
						})}
					</div>
				</div>
			</div>
			<div className="hidden md:block">
				<div className="ml-4 flex items-center md:ml-6">
					<div className="ml-3 relative">
						<div className="relative inline-block text-left">
							<div>
								<button
									type="button"
									aria-label="Menú de usuario"
									className="flex items-center justify-center w-full rounded-md  px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
									onMouseEnter={() => {
										resetHideAccountMenu();
										setShowAccountMenu(true);
									}}
									onMouseLeave={() => hideAccountMenu()}>
									{avatar}
								</button>
							</div>
							<div
								className={`${
									showAccountMenu ? 'block' : 'hidden'
								} origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 z-50`}
								onMouseEnter={() => {
									resetHideAccountMenu();
									setShowAccountMenu(true);
								}}
								onMouseLeave={() => hideAccountMenu()}>
								<div
									className="py-1 "
									role="menu"
									aria-orientation="vertical"
									aria-labelledby="options-menu">
									{menus}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
