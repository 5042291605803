import { News, Web } from 'data';
import { NewsCardComponent } from './news-card';

type Props = {
	news: News[];
	web: Web;
};

export const NewsGridComponent = ({ news, web }: Props) => {
	return (
		<section className="text-gray-600 body-font">
			<div className="container px-5 py-5 mx-auto">
				<div className="flex flex-wrap -m-4">
					{news!.map((aNew: News) => {
						return <NewsCardComponent key={aNew.code} aNew={aNew} web={web} />;
					})}
				</div>
			</div>
		</section>
	);
};
