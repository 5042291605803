import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSession, signIn } from 'next-auth/react';
import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { GetServerSidePropsContext } from 'next';

export const LoginComponent: FunctionComponent = () => {
	return (
		<div className="w-full">
			<div className="mx-auto	flex justify-self-center flex-col w-full max-w-md px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-700 sm:px-6 md:px-8 lg:px-10">
				<div className="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
					Acceder con tu cuenta
				</div>
				<div className="flex gap-4 item-center">
					<button
						type="button"
						className="py-2 px-4 flex justify-center items-center bg-red-500 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
						onClick={() => signIn('google')}>
						<FontAwesomeIcon icon={faGoogle} className="h-4 w-4 m-1" />
						Google
					</button>
					&nbsp;
					<button
						type="button"
						className="py-2 px-4 flex justify-center items-center  bg-blue-500 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
						onClick={() => signIn('facebook')}>
						<FontAwesomeIcon icon={faFacebook} className="h-4 w-4 m-1" />
						Google
					</button>
				</div>
			</div>
		</div>
	);
};

export async function LoginServerSideProps(context: GetServerSidePropsContext) {
	const session = await getSession(context);

	if (session?.user) {
		return {
			redirect: {
				destination: '/area-privada'
			}
		};
	}

	return {
		props: {}
	};
}
